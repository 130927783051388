<template>
  <div class="chooseLang">
    <div class="contentWrap">
      <div class="chooseLangText">{{$t('main.switch_locate_lan')}}</div>
      <div class="langsWrap">
        <div class="lang" :class="{currentLang: currentLang === language}" @click="langChooseHandle(language)" v-for="(language, i) in langsList" :key="i">
          {{langsData[language]['fullName']}}
        </div>
      </div>
      <div class="continue" :class="{active: currentLang !== ''}" @click="continueHandle">{{$t('web.clips_continue')}}</div>
    </div>
  </div>
</template>

<script>
  import constants from '@/utils/constants'

  export default {
    data() {
      return {
        currentLang: '',
        baseLangs: constants.baseLangs,
        langsData: constants.langsData,
      }
    },
    computed: {
      langsList() {
        return this.baseLangs.filter(item => item !== 'en')
      }
    },
    methods: {
      langChooseHandle(lang) {
        this.currentLang = lang
        this.$store.commit('set_userLanguage', lang)
      },
      continueHandle() {
        if (this.currentLang !== '') {
          this.$router.push('/clips')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .chooseLang {
    background: #F3F4F8;
    min-height: 100vh;
    .contentWrap {
      margin: 0 auto;
      padding: 30px 0;
      width: 1000px;
      .chooseLangText {
        font-weight: 500;
        font-size: 26px;
        color: #1F2039;
        text-align: center;
      }
      .langsWrap {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .lang {
          margin-bottom: 24px;
          width: 30%;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border: 2px solid #D6D8E5;
          border-radius: 8px;
          font-size: 18px;
          color: #4F5072;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background: #DCE4F2;
            border: 2px solid #004E94;
            color: #004E94;
          }
          &.currentLang {
            background: #DCE4F2;
            border: 2px solid #004E94;
            color: #004E94;
          }
        }
      }
      .continue {
        margin: 0 auto;
        width: 310px;
        height: 50px;
        line-height: 50px;
        border-radius: 50px;
        background: #D6D8E5;
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        &.active {
          background: #004E94;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background: #0A4172;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .chooseLang {
      .contentWrap {
        width: 96%;
        .langsWrap {
          .lang {
            width: 48%;
          }
        }
      }
    }
  }
</style>